import React, { useEffect, useState } from 'react'
import { AmplifySignIn, AmplifyAuthenticator } from '@aws-amplify/ui-react'
import { Spin } from 'antd'

export default function Login () {
  const [loading, setLoading] = useState(false)

  const handleLogin = (status) => {
    if (status === 'signedin') {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
        window.location.href = process.env.REACT_APP_ADMIN_SITE_URL
      }, 3000)
    }
  }

  useEffect(() => {
    document.title = 'Login | useMango™ Admin'
  }, [])

  return (
    <>
      {loading && (
        <div className='h-80 flex flex-col justify-center items-center'>
          <Spin size='large' tip='Logging in' spinning={loading} />
        </div>
      )}
      <AmplifyAuthenticator id='authenticator' handleAuthStateChange={handleLogin}>
        <AmplifySignIn
          style={{ height: '600px' }}
          id='signin'
          headerText='Sign in to useMango™ Admin'
          slot='sign-in'
          hideSignUp
        />
      </AmplifyAuthenticator>
    </>
  )
}
