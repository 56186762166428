import { call, put } from 'redux-saga/effects'
import { loginSuccess, logoutSuccess, setAuthorizationError } from '../actions'
import amplify from '../../services/amplify'

export function * loginHandle (_) {
  try {
    const loggedIn = yield call(amplify.checkLoggedIn, true)

    if (loggedIn === true) {
      yield put(loginSuccess())
      const isSystemAdmin = yield call(amplify.assertUserIsSysAdmin)
      if (!isSystemAdmin) {
        yield put(setAuthorizationError('User is not authorized to view this page'))
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function * logoutHandle (_) {
  try {
    yield call(amplify.logOut)
    yield put(logoutSuccess())
  } catch (e) {
    console.log(e)
  }
}
