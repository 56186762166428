import { useEffect, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'

import { Divider } from 'antd'
import './App.css'

import NavigationBar from './components/NavigationBar'
import Login from './pages/Login'
import moment from 'moment'

import { login } from './store/actions'
import { useDispatch, useSelector } from 'react-redux'

const ProtectedRoute = lazy(() => import('./components/ProtectedRoute'))
const Home = lazy(() => import('./pages/Home'))
const Accounts = lazy(() => import('./pages/Accounts/Accounts'))
const Account = lazy(() => import('./pages/Accounts/Account'))
const Administrators = lazy(() => import('./pages/Administrators'))
const Users = lazy(() => import('./pages/Users/Users'))
const User = lazy(() => import('./pages/Users/User'))
const SystemTesting = lazy(() => import('./pages/SystemTesting'))
const Reports = lazy(() => import('./pages/Reports/Reports'))

function App () {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)

  useEffect(() => {
    document.title = 'useMango™ Admin'
  }, [])

  useEffect(() => {
    dispatch(login())
  }, [dispatch])

  return (
    <>
      <Router>
        <NavigationBar loggedIn={isLoggedIn} />
        <main className='lg:w-3/4 flex flex-col container mx-auto rounded mt-4 px-4'>
          <Suspense>
            <Routes>
              <Route path='/accounts/' exact element={<ProtectedRoute isLoggedIn={isLoggedIn}><Accounts /></ProtectedRoute>} />
              <Route path='/accounts/:accountId' exact element={<ProtectedRoute isLoggedIn={isLoggedIn}><Account /></ProtectedRoute>} />
              <Route path='/administrators' exact element={<ProtectedRoute isLoggedIn={isLoggedIn}><Administrators /></ProtectedRoute>} />
              <Route path='/users/' exact element={<ProtectedRoute isLoggedIn={isLoggedIn}><Users /></ProtectedRoute>} />
              <Route path='/users/:email' exact element={<ProtectedRoute isLoggedIn={isLoggedIn}><User /></ProtectedRoute>} />
              <Route path='/system-testing' exact element={<ProtectedRoute isLoggedIn={isLoggedIn}><SystemTesting /></ProtectedRoute>} />
              <Route path='/reports' exact element={<ProtectedRoute isLoggedIn={isLoggedIn}><Reports /></ProtectedRoute>} />
              <Route path='/login' exact element={!isLoggedIn ? <Login /> : <Navigate to='/' />} />
              <Route path='/' exact element={<Home />} />
              <Route path='*' exact element={<Navigate to='/' />} />
            </Routes>
          </Suspense>
        </main>
      </Router>
      <footer className='container mx-auto py-4 px-8'>
        <Divider />
        <p className='text-right'>&copy; {moment().format('YYYY')} - useMango Ltd.</p>
      </footer>
    </>
  )
}

export default App
