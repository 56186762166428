import actions from '../../statics/actions'

export const login = () => {
  return {
    type: actions.LOGIN
  }
}

export const loginSuccess = () => {
  return {
    type: actions.LOGIN_SUCCESS
  }
}

export const logout = () => {
  return {
    type: actions.LOGOUT
  }
}

export const logoutSuccess = () => {
  return {
    type: actions.LOGOUT_SUCCESS
  }
}

export const setAuthorizationError = (payload) => {
  return {
    type: actions.SET_AUTHORIZATION_ERROR,
    payload
  }
}

export const updateReportControls = (payload) => {
  return {
    type: actions.UPDATE_REPORT_CONTROLS,
    payload
  }
}
