import { useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'

import amplify from '../services/amplify'

import { logout } from '../store/actions'

import { Dropdown, Menu, Button, Avatar, notification } from 'antd'
import { MenuOutlined, CloseOutlined, CaretDownFilled } from '@ant-design/icons'

import { useDispatch, useSelector } from 'react-redux'
import md5 from 'blueimp-md5'

export default function NavigationBar () {
  const [hiddenMenu, setHiddenMenu] = useState(false)
  const [username, setUsername] = useState('')

  const dispatch = useDispatch()
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)

  useEffect(() => {
    getUsername()
  }, [])

  const getUsername = async () => {
    const result = await amplify.getUserName()
    setUsername(result)
  }

  return (
    <nav className='bg-mango'>
      <div className='nav-container'>
        <div className='flex justify-between'>
          <div className={`flex  ${hiddenMenu ? 'flex-col md:flex-row' : 'space-x-7'}`}>
            <Link to='/' className='flex py-4 px-2'>
              <span className='font-semibold text-lg'>useMango&trade; Admin</span>
            </Link>
            {isLoggedIn && (
              <div
                className={`md:flex ${
                  hiddenMenu ? 'flex flex-col md:flex-row md:items-center' : 'hidden'
                }`}
              >
                <NavLink to='/accounts' className='nav-link'>
                  Accounts
                </NavLink>
                <NavLink to='/administrators' className='nav-link'>
                  Administrators
                </NavLink>
                <NavLink to='/users' className='nav-link'>
                  Users
                </NavLink>
                <NavLink to='/system-testing' className='nav-link'>
                  System Testing
                </NavLink>
                <NavLink to='/reports' className='nav-link'>
                  Reports
                </NavLink>
              </div>
            )}
          </div>
          <div className='flex space-x-3 py-4'>
            <div className='md:flex'>
              {isLoggedIn
                ? (
                  <Dropdown
                    className='md:flex'
                    overlay={
                      <Menu
                        items={[
                          {
                            label: (
                              <Link
                                to='/login'
                                onClick={() => {
                                  dispatch(logout())
                                  notification.success({
                                    message: 'Logged out successfully'
                                  })
                                }}
                              >
                                Log out
                              </Link>
                            ),
                            key: '0'
                          }
                        ]}
                      />
                  }
                    trigger={['click']}
                  >
                    <div
                      className='cursor-pointer flex items-center'
                      onClick={(e) => e.preventDefault()}
                      title={username}
                    >
                      <Avatar
                        style={{ color: '#ffc107', backgroundColor: '#273444' }}
                        src={`https://www.gravatar.com/avatar/${md5(username)}?s=30&d=404`}
                      >
                        {(
                          username.charAt(0) + username.charAt(username.indexOf('.') + 1)
                        ).toUpperCase()}
                      </Avatar>
                      <CaretDownFilled />
                    </div>
                  </Dropdown>
                  )
                : (
                  <div>
                    <Link to='/login'>Log in</Link>
                  </div>
                  )}
            </div>
            <div className='md:hidden flex'>
              {isLoggedIn && (
                <Button
                  type='primary' style={{ background: 'transparent', border: '1px solid #333' }}
                  icon={hiddenMenu ? <CloseOutlined style={{ color: '#333' }} /> : <MenuOutlined style={{ color: '#333' }} />}
                  onClick={() => {
                    setHiddenMenu(!hiddenMenu)
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}
