import { takeLatest, all } from 'redux-saga/effects'
import { loginHandle, logoutHandle } from './handlers'
import actions from '../../statics/actions'

export function * watcherSaga () {
  yield all([
    yield takeLatest(actions.LOGIN, loginHandle),
    yield takeLatest(actions.LOGOUT, logoutHandle)
  ])
}
