const actions = {
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  UPDATE_REPORT_CONTROLS: 'UPDATE_REPORT_CONTROLS',
  SET_AUTHORIZATION_ERROR: 'SET_AUTHORIZATION_ERROR'
}

export default actions
