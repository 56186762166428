import { combineReducers } from 'redux'

import actions from '../../statics/actions'

const auth = (state = { isLoggedIn: false }, action) => {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return { isLoggedIn: true }
    case actions.LOGOUT_SUCCESS:
      localStorage.clear()
      return { isLoggedIn: false }
    default:
      return state
  }
}

const unauthorizedError = (state = null, action) => {
  switch (action.type) {
    case actions.SET_AUTHORIZATION_ERROR:
      return action.payload
    default:
      return state
  }
}

const reportControls = (
  state = {
    fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    toDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
    periodLabel: 'This Month',
    timeSegment: 'week',
    reportType: 'executionHistory'
  },
  action
) => {
  switch (action.type) {
    case actions.UPDATE_REPORT_CONTROLS:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const allReducers = combineReducers({
  auth,
  unauthorizedError,
  reportControls
})

export default allReducers
